import { TCreditCardType, TPayment, getText } from "swiipe.portal.shared"
import DefaultCardLogo from "../../src/images/icons/card-blue.png"
import DankortLogo from "../../src/images/swCardLogos/dankort-card-logo.png"
import ForbrugsforeningenLogo from "../../src/images/swCardLogos/forbrugsforeningen-card-logo.png"
import MasterCardLogo from "../../src/images/swCardLogos/mastercard-card-logo.png"
import VisaLogo from "../../src/images/swCardLogos/visa-card-logo.png"
import { IMerchantProviderConfig } from "../store/reducers/merchantServicesReducer"
import { IGatewayProvider, TOnboardingStatus } from "../type/IProvider"
import { TProviderName } from "./../type/IProvider"

export enum Psp {
    QUICKPAY = "Quickpay",
    DIBS = "DIBS",
    EPAY = "Epay",
    TEST = "Testgateway",
}

export interface IPsp {
    type: Psp
    title: string
    short: string
    requireMerchantId?: boolean
    requireApiKey?: boolean
}

const psps: IPsp[] = [
    { type: Psp.QUICKPAY, title: "Quickpay", short: "qp", requireApiKey: true },
    { type: Psp.DIBS, title: "DIBS", short: "dibs", requireMerchantId: true },
    { type: Psp.EPAY, title: "Epay", short: "epay", requireMerchantId: true },
    { type: Psp.TEST, title: "Testgatway", short: "testgateway", requireMerchantId: false },
]

export function getPsps(): IPsp[] {
    return psps
}

const pspsSupportedByMobileCheckout = [Psp.DIBS, Psp.EPAY]

export function getPspsSupportedByMobileCheckout(): IPsp[] {
    return psps.filter((p) => pspsSupportedByMobileCheckout.indexOf(p.type) >= 0)
}

export const getLogoByCardType = (type?: TCreditCardType, onDefaultEmpty?: boolean) => {
    if (!type) {
        return onDefaultEmpty ? "" : DefaultCardLogo
    }
    switch (type) {
        case "Visa":
            return VisaLogo
        case "MasterCard":
            return MasterCardLogo
        case "Dankort":
            return DankortLogo
        case "Forbrugsforeningen":
            return ForbrugsforeningenLogo
        default:
            return onDefaultEmpty ? "" : DefaultCardLogo
    }
}

export const resolvePaymentTypeName = (paymentType: TPayment): string => {
    switch (paymentType) {
        case "AccountToAccount":
            return getText("serviceNames.a2a")
        case "CreditCard":
            return getText("serviceNames.cardPayments")
        case "MobilePayOnline":
            return getText("serviceNames.mpo")
        case "Swish":
            return getText("serviceNames.swish")
        case "Vipps":
            return getText("serviceNames.vipps")
        case "ApplePay":
            return getText("serviceNames.applepay")
        case "KlarnaPayLater":
            return getText("serviceNames.klarnaPayLater")
        case "KlarnaFinancing":
            return getText("serviceNames.klarnaFinancing")
        case "Viabill":
            return getText("serviceNames.viabill")
        case "Anyday":
            return getText("serviceNames.anyday")
        case "ResursInvoice":
            return getText("serviceNames.resursInvoice")
        case "ResursPartPayment":
            return getText("serviceNames.resursPartPayment")
        case "ResursRevolvingCredit":
            return getText("serviceNames.resursRevolvingCredit")
    }
}

export const getHiddenCardNumber = (number: string) => {
    return "**** " + number.slice(number.length - 4)
}

export const getFormattedCardNumber = (number: string) => {
    return number.replace(/^(.{4})(.{4})(.{4})(.{4})$/, "$1 $2 $3 $4")
}

export const getFormattedCardExpiryYear = (year: string) => {
    return year.slice(2)
}

export function isProviderEnabledAndReady(
    gatewayProviders: IGatewayProvider[] | undefined,
    paymentType: TPayment,
    providerName: TProviderName
): boolean {
    return getProviderStatus(gatewayProviders, paymentType, providerName, false) === "Ready"
}

export function getProviderStatus(
    gatewayProviders: IGatewayProvider[] | undefined,
    paymentType: TPayment | TPayment[], // array if at least one has be to enabled
    providerName: TProviderName,
    ignoreEnabled?: boolean
): TOnboardingStatus | undefined {
    if (!gatewayProviders) {
        return undefined
    }

    let provider: IGatewayProvider | undefined
    if (Array.isArray(paymentType)) {
        provider = gatewayProviders.find(
            (gp) => paymentType.some((pt) => gp.paymentType == pt) && gp.providerName == providerName
        )
    } else {
        provider = gatewayProviders.find((gp) => gp.paymentType == paymentType && gp.providerName == providerName)
    }

    if (!provider) {
        return undefined
    }

    if (provider.enabled || ignoreEnabled) {
        return provider.status
    }

    return "ActionRequired"
}

export function getProvider(
    paymentType: TPayment,
    providerName: TProviderName,
    gatewayProviders?: IGatewayProvider[]
): IGatewayProvider | undefined {
    if (!gatewayProviders) {
        return undefined
    }
    const provider = gatewayProviders.find((gp) => gp.paymentType == paymentType && gp.providerName == providerName)
    if (!provider) {
        return undefined
    }
    return provider
}

export function allWebshopsOnboardedForProvider(
    onboardingStatuses: IMerchantProviderConfig | undefined,
    webshopIds: string[],
    paymentType: TPayment,
    providerName: TProviderName
) {
    if (!onboardingStatuses) {
        return false
    }
    const configs = webshopIds.map((wid) => onboardingStatuses[wid])

    const hasUnonboardedWebshops = configs.find((c) => {
        if (!c) {
            return false
        }

        const providerStatus = getProviderStatus(c.gatewayProviders, paymentType, providerName)
        return !providerStatus || providerStatus == "ActionRequired"
    })

    return !hasUnonboardedWebshops
}

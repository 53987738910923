import { createEndpoint0, createEndpoint1, createEndpoint2, createEndpoint3 } from "swiipe.portal.shared"

const apiEndpoints = {
    Self: {
        checkCookie: createEndpoint0("post", "/checkCookie", { endpoint: "Self" }),
        getConfiguration: createEndpoint0("get", "/api/configuration/GetServerConfigurationEndpoints", { endpoint: "Self" }),
    },
    Auth: {
        login: createEndpoint0("post", "/auth/login", { isOpen: true }),
        forgotPassword: createEndpoint0("post", "/auth/forgotPassword", { isOpen: true }),
        resetPassword: createEndpoint0("post", "/auth/resetPassword", { isOpen: true }),
        changePassword: createEndpoint0("post", "/auth/changePassword", { isOpen: true }),
    },
    Core: {
        getUserData: createEndpoint0("get", "/core/personal/user"),
        register: createEndpoint0("post", "/core/personal/createUserFromPortal", { isOpen: true }),
        userExists: createEndpoint0("get", "/core/personal/user/exists", { isOpen: true }),
        addDataWithToken: createEndpoint0("post", "/core/personal/user/addDataWithToken", { isOpen: true }),

        getUserRelations: createEndpoint0("get", "/core/userRelation/userRelations"),
        addUserRelation: createEndpoint0("post", "/core/userRelation/addUser"),
        deleteUserRelation: createEndpoint0("delete", "/core/userRelation/deleteUserRelation"),
        changeActiveStatusUserRelation: createEndpoint0("put", "/core/userRelation/changeActiveStatus"),
        changeIsAdminStatusUserRelation: createEndpoint0("put", "/core/userRelation/changeIsAdminStatus"),
        getUsersForOrganization: createEndpoint2("get", "/core/userRelation/users/{organizationType}/{organizationId}"),

        deleteWebshop: createEndpoint0("delete", "/core/merchant/deleteWebshop"),

        getPartnerData: createEndpoint1("get", "/core/partner/{partnerId}"),
        updatePartnerContactData: createEndpoint1("put", "/core/partner/updatePartnerContactData/{partnerId}"),
        signupPartner: createEndpoint0("post", "/core/partner/signup", { isOpen: true }),
        getPartnerMerchantRelations: createEndpoint1("get", "/core/partner/merchants/{partnerId}"),
        resendConfirmEmail: createEndpoint3("get", "/core/partner/resendConfirmEmail/{partnerId}/{merchantId}/{toEmail}"),
        getAllPartnerMerchantRelations: createEndpoint0("get", "/core/partner/merchants/all"),

        ensureBillingAddress: createEndpoint0("post", "/core/billingAddress/ensureBillingAddressInternal"),
        ensureDeliveryLocation: createEndpoint0("post", "/core/deliveryLocation/ensureDeliveryLocationInternal"),
        deleteDeliveryLocation: createEndpoint1("delete", "/core/deliveryLocation/deleteDeliveryLocation/{deliveryLocationId}"),
    },
    Identity: {
        getApiKeys: createEndpoint1("get", "/identity/apiKeys/{swMerchantId}"),
        addApiKey: createEndpoint1("post", "/identity/apiKeys/{swMerchantId}"),
        getUserRelationsSearchData: createEndpoint0("get", "/identity/user/getUserRelationsSearchData"),
        getSwiipePlan: createEndpoint1("get", "/identity/swiipePlan/{swMerchantId}"),
        getCompanyDetails: createEndpoint0("get", "/identity/organization/getCompanyDetails"),
        setCompanyDetails: createEndpoint0("post", "/identity/organization/setCompanyDetails"),
        updateMerchantCvr: createEndpoint0("put", "/identity/merchant/updateMerchantCvr"),
        updatePartnerCvr: createEndpoint0("put", "/identity/partner/updateCvr"),
        updateUserDataAsMasterPartner: createEndpoint0("post", "/identity/user/updateUserDataAsMasterPartner"),
        updateUserData: createEndpoint0("patch", "/identity/user/updateUserData"),
        changeOrganizationOwner: createEndpoint0("put", "/identity/organization/changeOwner"),
        uploadWebshopFile: createEndpoint0("post", "/identity/webshop/uploadWebshopFile"),
        getFeatureDetails: createEndpoint0("get", "/identity/webshop/getFeaturesDetailsAuth"),
        setFeatureStatus: createEndpoint0("post", "/identity/webshop/setFeatureStatusAuth"),
        setWinbackDetails: createEndpoint0("post", "/identity/winback/setWinbackDetails"),
        getWinbackDetails: createEndpoint0("get", "/identity/winback/getWinbackDetailsAuth"),
        updateWinbackSmsSenderNameStatus: createEndpoint0("post", "/identity/winback/updateSmsSenderNameStatus"),
        getWinbackSmsSenderNameStatusesAll: createEndpoint0("get", "/identity/winback/getSmsSenderNameStatusesAll"),
        getMerchantDetails: createEndpoint0("get", "/identity/merchant/getMerchantDetails"),
        setMerchantDetails: createEndpoint0("post", "/identity/merchant/setMerchantDetails"),
        getOwnersContactInfo: createEndpoint0("get", "/identity/merchant/getOwnersContactInfo"),
        deleteMerchantContact: createEndpoint0("delete", "/identity/merchant/contact"),
        setWebshopDetails: createEndpoint0("post", "/identity/Webshop/setWebshopDetails"),
        getWebshopDetails: createEndpoint0("get", "/identity/Webshop/getWebshopDetailsAuth"),
        sendServiceNotificationEmail: createEndpoint0("post", "/identity/swiipeService/sendServiceNotificationEmail"),
        editWebshopAsMasterPartner: createEndpoint0("patch", "/identity/webshop/editWebshopAsMasterPartner"),
        setOrganizationDeleted: createEndpoint0("post", "/identity/organization/setDeleted"),
        requestOrganizationDeletion: createEndpoint0("post", "/identity/organization/requestDeletion"),
        createWebshop: createEndpoint0("post", "/identity/merchant/createWebshop"),
        createMerchant: createEndpoint0("post", "/identity/merchant/createMerchant", { isOpen: true }),
        partnerCreateMerchant: createEndpoint0("post", "/identity/partner/partnerCreateMerchant"),
        getMerchantPreOnboardingDetails: createEndpoint0("get", "/identity/merchantPreOnboardings/details"),
        addMerchantPreOnboardingEvent: createEndpoint0("post", "/identity/merchantPreOnboardings/events"),
    },
    Clearhaus: {
        saveOnboardingData: createEndpoint0("post", "/clearhaus/onboarding/save"),
        onboard: createEndpoint0("post", "/clearhaus/onboarding/onboard"),
        uploadFile: createEndpoint0("post", "/clearhaus/onboarding/uploadFile"),
        editApplication: createEndpoint0("post", "/clearhaus/onboarding/edit"),

        getApplicationComments: createEndpoint2("get", "/clearhaus/onboarding/getApplicationComments/{swMerchantId}/{webshopId}"),
        alreadyHasClearhausOnboarding: createEndpoint0("post", "/clearhaus/onboarding/alreadyHasClearhausOnboarding"),

        registerOnboardingStarted: createEndpoint0("post", "/clearhaus/onboarding/registerOnboardingStarted"),
        updateIsInstantStatus: createEndpoint0("put", "/clearhaus/onboarding/updateIsInstantStatus"),
        getOnboardingData: createEndpoint0("get", "/clearhaus/onboarding/getData"),

        getApplicationOnboardingState: createEndpoint0("get", "/clearhaus/onboarding/getApplicationOnboardingState"),

        submitAtClearhaus: createEndpoint0("post", "/clearhaus/onboarding/submitAtClearhaus"),
        sendConfirmationEmail: createEndpoint0("post", "/clearhaus/onboarding/sendConfirmationEmail"),

        updateApplicationInfo: createEndpoint0("patch", "/clearhaus/onboarding/updateApplicationInfo"),
        acceptUpdateApplicationInfo: createEndpoint0("patch", "/clearhaus/onboarding/acceptUpdateApplicationInfo"),

        adminGetApplicationsList: createEndpoint0("get", "/clearhaus/onboarding/admin/getApplicationsList"),
        adminDownloadApplicationZip: createEndpoint1("get", "/clearhaus/onboarding/admin/downloadApplicationZip/{onboardingId}"),

        cancelOnboarding: createEndpoint0("post", "/clearhaus/onboarding/cancelOnboarding"),
    },
    Plugin: {
        recordWebshopSnapshot: createEndpoint0("post", "/plugin/webshopData/recordWebshopSnapshot/fromPortal"),
    },
    PaymentIQ: {
        chooseSwiipePspPlan: createEndpoint0("post", "/paymentiq/generalOnboarding/chooseSwiipePspPlan"),
        getOnboardingStatus: createEndpoint0("post", "/paymentiq/onboardingStatus/getOnboardingStatus"),
        setOnboardingStatus: createEndpoint0("post", "/paymentiq/onboardingStatus/setOnboardingStatus"),
        getOnboardingStatuses: createEndpoint0("post", "/paymentiq/onboardingStatus/getOnboardingStatuses"),
        setOnboardingStatuses: createEndpoint0("post", "/paymentiq/onboardingStatus/setOnboardingStatuses"),
        createMpoOnboarding: createEndpoint0("post", "/paymentiq/mobilepayOnboarding"),
        getMpoOnboardingData: createEndpoint0("get", "/paymentiq/mobilepayOnboarding"),
        mpoOnboardForMpoApi: createEndpoint0("post", "/paymentiq/mobilepayOnboarding/forMpoApi"),
        createDankortOnboarding: createEndpoint0("post", "/paymentiq/creditcardOnboarding/dankort"),
        getDankortOnboardingData: createEndpoint0("get", "/paymentiq/creditcardOnboarding/dankort"),
        createForbrugsforeningenOnboarding: createEndpoint0("post", "/paymentiq/creditcardOnboarding/forbrugsforeningen"),
        getForbrugsforeningenOnboardingData: createEndpoint0("get", "/paymentiq/creditcardOnboarding/forbrugsforeningen"),
        createSwishOnboarding: createEndpoint0("post", "/paymentiq/swishOnboarding"),
        getSwishOnboardingData: createEndpoint0("get", "/paymentiq/swishOnboarding"),
        createSwishCsr: createEndpoint0("post", "/paymentiq/swishOnboarding/createCsr"),
        createApplePayOnboarding: createEndpoint0("post", "/paymentiq/applePayOnboarding"),
        getApplePayOnboardingData: createEndpoint0("get", "/paymentiq/applePayOnboarding"),
        createApplePayCsr: createEndpoint0("post", "/paymentiq/applePayOnboarding/createCsr"),
        getVippsOnboardingData: createEndpoint0("get", "/paymentiq/vippsOnboarding"),
        createVippsOnboarding: createEndpoint0("post", "/paymentiq/vippsOnboarding"),
        getKlarnaOnboardingData: createEndpoint0("get", "/paymentiq/klarnaOnboarding"),
        createKlarnaOnboarding: createEndpoint0("post", "/paymentiq/klarnaOnboarding"),
        sandboxOnboarding: createEndpoint0("post", "/paymentiq/generalOnboarding/sandboxOnboarding"),
        checkPiqDuplicates: createEndpoint0("post", "/paymentiq/onboardingStatus/checkPiqDuplicates"),
    },
    Viabill: {
        onboardByRegistration: createEndpoint0("post", "/viabill/onboarding/register"),
        onboardByLogin: createEndpoint0("post", "/viabill/onboarding/login"),
        getOnboardingData: createEndpoint0("get", "/viabill/onboarding/data"),
    },
    Anyday: {
        onboardConnect: createEndpoint0("post", "/anyday/onboarding/connect"),
        getOnboardingData: createEndpoint0("get", "/anyday/onboarding/data"),
    },
    Payments: {
        getAllGateways: createEndpoint0("get", "/payments/gateways/fromPortal"),
        patchGateway: createEndpoint1("patch", "/payments/gateways/{gatewayId}/fromPortal"),
        getUserPaymentMethods: createEndpoint0("get", "/payments/paymentMethod/getUserPaymentMethods"),
        initiateAddPaymentMethodFlow: createEndpoint0("post", "/payments/paymentMethod/initiateAddFlow"),
        completeAddPaymentMethodFlow: createEndpoint0("post", "/payments/paymentMethod/completeAddFlow"),
        getPaymentMethodRelations: createEndpoint0("get", "/payments/paymentMethod/relations"),
        setPrimaryPaymentMethodRelation: createEndpoint0("post", "/payments/paymentMethod/setPrimaryRelation"),
        deletePaymentMethodRelation: createEndpoint0("delete", "/payments/paymentMethod/relation"),
        deleteUserPaymentMethod: createEndpoint0("delete", "/payments/paymentMethod"),
        getTransactionsPerWebshop: createEndpoint0("post", "/payments/transaction/getTransactions"),
        getOrdersPerWebshop: createEndpoint0("post", "/payments/order/getOrders"),
        getSingleOrderPerWebshop: createEndpoint2("get", "/payments/order/getSingleOrder/{webshopId}/{orderId}"),
        getPaymentOrderDetails: createEndpoint0("get", "/payments/order/getPaymentOrderDetails"),
        getCsvTransformed: createEndpoint0("post", "/payments/transaction/getCsvTransformed"),
        reportTransactionActionFail: createEndpoint0("post", "/payments/transaction/reportTransactionActionFail"),
        capture: createEndpoint0("post", "/payments/payments/captureFromPortal"),
        refund: createEndpoint0("post", "/payments/payments/refundFromPortal"),
        void: createEndpoint0("post", "/payments/payments/voidFromPortal"),
        reportFraud: createEndpoint0("post", "/payments/order/reportFraud"),
        bulk: createEndpoint0("post", "/payments/payments/bulkFromPortal"),
        getLatestPaymentLinkData: createEndpoint0("get", "/payments/paymentLink/getLatestPaymentLinkData"),
        createPaymentLinkAsMerchant: createEndpoint0("post", "/payments/paymentLink/createAsMerchant"),
        sendPaymentLinkNotification: createEndpoint0("post", "/payments/paymentLink/sendNotification"),
        getPaymentLinksPerWebshop: createEndpoint0("post", "/payments/paymentLink/getPaymentLinks"),
        getOrdersExport: createEndpoint0("post", "/payments/order/export"),
        adminUpdateTransaction: createEndpoint1("patch", "/payments/transaction/adminUpdateTransaction/{transactionId}"),
    },
    A2A: {
        connectA2aAccounts: createEndpoint0("post", "/a2a/merchant/connectAccounts"),
        merchantRelatedAccounts: createEndpoint0("get", "/a2a/merchant/relatedAccounts"),
        gatewayDetailsEnsure: createEndpoint0("post", "/a2a/gateway/details/ensure"),
        webshopPaymentsAccount: createEndpoint0("post", "/a2a/merchant/webshopPaymentsAccount"),
        deleteWebshopPaymentsAccount: createEndpoint0("delete", "/a2a/merchant/webshopPaymentsAccount"),
    },
    Resurs: {
        getResursStores: createEndpoint0("get", "/resurs/onboarding/resursStores"),
        cancelOnboarding: createEndpoint0("post", "/resurs/onboarding/cancel"),
        notifyNoStoresSignUp: createEndpoint0("post", "/resurs/onboarding/notifyNoStoresSignUp"),
        onboardWebshop: createEndpoint0("post", "/resurs/onboarding"),
    },
    Content: {
        getReleaseNotes: createEndpoint0("get", "/content/ReleaseNotes/getAll", { isOpen: true, endpoint: "PublicContent" }),
        getReleaseNotesInternal: createEndpoint0("get", "/content/ReleaseNotes/getAllInternal"),
        getCountryRegionAssets: createEndpoint0("get", "/content/assets/countryRegion", { isOpen: true }),
    },
    Order: {
        getOrderDetails: createEndpoint1("get", "/order/orders/{id}/detailsAuth"),
    },
    Invoicing: {
        getInvoicesBookedSimple: createEndpoint0("get", "/invoicing/invoice/GetInvoicesBookedSimple"),
        getInvoicePdf: createEndpoint0("get", "/invoicing/invoice/InvoicePdf"),
        getOrganization: createEndpoint0("get", "/invoicing/organization"),
        patchOrganization: createEndpoint0("patch", "/invoicing/organization"),
        getPrices: createEndpoint0("get", "/invoicing/price"),
    },
    Analytics: {
        getDashboardData: createEndpoint0("get", "/analytics/reporting/dashboardData"),
        getDashboardUpdatedDate: createEndpoint0("get", "/analytics/reporting/dashboardUpdatedDate"),
        getDashboardDataExport: createEndpoint0("post", "/analytics/reporting/dashboardData/export"),
    },
    Legal: {
        getTermsAsLoggedIn: createEndpoint0("get", "/legal/terms/asLoggedIn"),
        getTerms: createEndpoint0("get", "/legal/terms"),
        getTermsStatus: createEndpoint0("get", "/legal/terms/status"),
        acceptTerms: createEndpoint0("post", "/legal/terms/accept"),
    },
    GoogleAnalytics: {
        addWebshopProperties: createEndpoint0("post", "/googleAnalytics/onboarding/webshopProperties"),
        getWebshopProperties: createEndpoint0("get", "/googleAnalytics/onboarding/webshopProperties"),
    },
}

type IApiEndpoints = typeof apiEndpoints
export const endpoints: IApiEndpoints = apiEndpoints

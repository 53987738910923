export const allPaymentTypes = [
    "CreditCard",
    "MobilePayOnline",
    "AccountToAccount",
    "Viabill",
    "Anyday",
    "Swish",
    "Vipps",
    "KlarnaPayLater",
    "KlarnaFinancing",
    "ApplePay",
    "ResursInvoice",
    "ResursPartPayment",
    "ResursRevolvingCredit",
] as const

export type TPayment = (typeof allPaymentTypes)[number]

export const piqPaymentTypes: TPayment[] = [
    "CreditCard",
    "MobilePayOnline",
    "Swish",
    "Vipps",
    "KlarnaPayLater",
    "KlarnaFinancing",
    "ApplePay",
]

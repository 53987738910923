import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    AccordionWithChildren,
    IInvoicingPrice,
    PageContainer,
    PageWrapper,
    TEnv,
    TPlatformValue,
    TSwiipeService,
    WebshopDetails,
    getText,
    getTextWithInvoicingPrices,
    hasValue,
    mapMerchantOnboardingServicesSubFormToServices,
    sharedConfigurationSelectors,
    useSelector,
} from "swiipe.portal.shared"
import Plugins, { IPlugin } from "../../components/merchant/Plugin"
import WebshopSelect, { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import AnywhereMobileLogo from "../../images/swFeatureServices/anywhere-mobile-logo.svg"
import PlusSellLogo from "../../images/swFeatureServices/plussell-logo.png"
import ReturnSellLogo from "../../images/swFeatureServices/returnsell-logo.png"
import WinBackLogo from "../../images/swFeatureServices/winback-logo.png"
import MobilePayOnlineTextLogo from "../../images/swPaymentTypes/mobilepay-logo.png"
import { getClearhausPluginStatus, getClearhausPluginStatusType } from "../../services/clearhausService"
import { getImage } from "../../services/imageLanguageService"
import { useMerchantPreOnboardingDetails } from "../../services/merchantOfferService"
import { navigationService } from "../../services/navigationService"
import { getProvider, getProviderStatus } from "../../services/paymentService"
import { filterPluginsByPlatform, useCalculatedPluginsDependencies } from "../../services/pluginService"
import { getPspPriceHtml } from "../../services/pspService"
import { getTermsForSwiipeService } from "../../services/swiipeServicesService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { IGatewayDto } from "../../store/reducers/gatewayReducer"
import { legalSelectors } from "../../store/reducers/legalReducer"
import { IMerchantPreOnboardingDetails } from "../../store/reducers/merchantPreOnboardingSlice"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IWebshopProviderConfig } from "../../store/reducers/merchantServicesReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { getMerchantPreOnboardingDetailsThunk } from "../../store/thunks/merchantPreOnboardingThunks"
import { ensureTermsStatusThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TProviderName, TSwiipePlan } from "../../type/IProvider"
import { IClearhausApplicationOnboardingState } from "../../type/clearhaus/IClearhausApplicationOnboardingState"
import { IWebshopFeaturesDetails } from "../../type/feature/IFeatureDetails"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./MerchantPluginsPage.scss"

const getPluginData = (webshopDetails: WebshopDetails | undefined, merchantCountry: string, env: TEnv): IPlugin[] => {
    const t = getText
    const swiipeWoocommerce: IPlugin = {
        id: undefined,
        platforms: ["woocommerce"] as TPlatformValue[],
        price: getText("plugins.freePrice"),
        isPlugin: true,
        isFetched: true,
        logo: getImage("checkoutLogo"),
        logoWidth: 175,
        title: "",
        installationLink: "https://docs.swiipe.com",
        buttonLink: "https://swiipeprodapidb.blob.core.windows.net/woocommerceplugin/swiipe-checkout.zip",
        pluginDescription: t("plugins.swiipeDescription"),
        legalTextType: ["TermsMerchant"],
        legalTextLinkText: t("plugins.here"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    const swiipeMagento: IPlugin = {
        id: undefined,
        platforms: ["magento"] as TPlatformValue[],
        price: t("plugins.freePrice"),
        isPlugin: true,
        isFetched: true,
        logo: getImage("checkoutLogo"),
        logoWidth: 175,
        title: "",
        installationLink: "https://docs.swiipe.com",
        buttonLink: "https://plugin-repo.swiipe.com/magento1plugins/swiipe-checkout-dk-latest.zip",
        pluginDescription: t("plugins.swiipeDescriptionMagento"),
        legalTextType: ["TermsMerchant"],
        legalTextLinkText: t("plugins.here"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    const swiipeMagento2: IPlugin = {
        id: undefined,
        platforms: ["magento2"] as TPlatformValue[],
        price: t("plugins.freePrice"),
        isPlugin: true,
        isFetched: true,
        logo: getImage("checkoutLogo"),
        logoWidth: 205,
        title: "",
        installationLink: "https://docs.swiipe.com",
        buttonLink: "https://plugin-repo.swiipe.com/magento2plugins/composer-dk/composer-satis/index.html",
        pluginDescription: t("plugins.swiipeDescriptionMagento"),
        legalTextType: ["TermsMerchant"],
        legalTextLinkText: t("plugins.here"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    const swiipePrestashop: IPlugin = {
        id: undefined,
        platforms: ["prestashop"] as TPlatformValue[],
        price: t("plugins.freePrice"),
        isPlugin: true,
        isFetched: true,
        logo: getImage("checkoutLogo"),
        logoWidth: 175,
        title: "",
        installationLink: "https://docs.swiipe.com",
        buttonLink: "https://plugin-repo.swiipe.com/prestashopplugins/swiipe.zip",
        pluginDescription: t("plugins.swiipeDescription"),
        legalTextType: ["TermsMerchant"],
        legalTextLinkText: t("plugins.here"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    if (!webshopDetails || !merchantCountry) {
        return []
    }

    return filterPluginsByPlatform([swiipeWoocommerce, swiipeMagento, swiipeMagento2, swiipePrestashop], webshopDetails.platform)
}

const useServiceData = (
    webshop: {
        id: string
        details: WebshopDetails | undefined
        features: IWebshopFeaturesDetails
        providers: IWebshopProviderConfig | undefined
    },
    merchant: {
        isDeletionPending: boolean
        country: string
        gateways: IGatewayDto[] | undefined
    },
    global: {
        env: TEnv
        invoicingPrices: IInvoicingPrice[] | undefined
        swiipePlan: TSwiipePlan | undefined
    },
    clearhaus: {
        applicationState: IClearhausApplicationOnboardingState | undefined
    },
    sandbox: {
        getSandboxOnboardingAction: (providerName: TProviderName) => (() => Promise<void>) | undefined
    }
): IPlugin[] => {
    const t = getText
    const swiipePlanSelected = !!global.swiipePlan

    const clearhausCcStatus = getProviderStatus(webshop.providers?.gatewayProviders, "CreditCard", "Clearhaus")
    const clearhausPluginStatus = getClearhausPluginStatus(
        getClearhausPluginStatusType(
            swiipePlanSelected,
            merchant.isDeletionPending,
            global.env,
            webshop.providers,
            clearhausCcStatus,
            clearhaus.applicationState
        ),
        webshop.id
    )
    const swiipePayments: IPlugin = {
        id: "CreditCard",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        price: getTextWithInvoicingPrices("plugins.swiipePaymentsPrice", global.invoicingPrices, {
            pspMonthlyBasic: { type: "SwiipePspMonthlyBasic" },
            pspTxBasic: { type: "SwiipePspTransactionBasic" },
            pspMonthlyBusiness: { type: "SwiipePspMonthlyBusiness" },
            pspTxBusiness: { type: "SwiipePspTransactionBusiness" },
        }),
        editNode: swiipePlanSelected && !merchant.isDeletionPending && (
            <>
                <div>
                    {t("plugins.editNode.pspPlan")} <br />
                    {t("plugins.editNode.acquiring")}: Clearhaus <br />
                    <br />
                    <span onClick={() => navigationService.navigate("/clearhaus_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            </>
        ),
        logo: getImage("cardPaymentsLogo"),
        isFetched: clearhausPluginStatus.isFetched,
        logoWidth: 180,
        logoTopMargin: 15,
        title: t("plugins.swiipePaymentsTitle"),
        buttonFunc: sandbox.getSandboxOnboardingAction("Clearhaus"),
        pluginDescription: t("plugins.swiipePaymentsOnboardingDescription"),
        textUnderDescription: "plugins.swiipePaymentsTextUnderDescription",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        legalTextType: ["TermsPSP", "TermsAcquiringClearhaus"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.psp"), t("serviceNames.clearhaus")],
        cardTypes: ["Visa", "MasterCard"],

        isOnboarded: clearhausPluginStatus.isOnboarded,
        onboardedText:
            (clearhausPluginStatus.isNeedMoreInfo && t("plugins.needMoreInfo")) ||
            (clearhausPluginStatus.isSavedNoStagesSubmitted && t("plugins.applicationStarted")) ||
            (clearhausPluginStatus.isAwaitingApproval && t("plugins.waitingapproval")) ||
            t("plugins.onboarded"),
        onboardedTextItalic: clearhausPluginStatus.isAwaitingApproval,
        onboardedSecondaryText: (clearhausPluginStatus.isChangesRequested && t("plugins.changesRequested")) || undefined,
        onboardedSecondaryTextItalic: true,
        activateBtnText: (clearhausPluginStatus.canEditApplication && t("plugins.completeApplication")) || undefined,
        hintBtnSection:
            (clearhausPluginStatus.showLimitedAccessHint && {
                title: t("plugins.clearhausLimitedAccess.title"),
                hint: t("plugins.clearhausLimitedAccess.hint"),
            }) ||
            undefined,
        canEdit: clearhausPluginStatus.canEditApplication || clearhausPluginStatus.canViewApplicationData || false,
        editBtnText:
            (clearhausPluginStatus.canEditApplication && t("plugins.completeApplication")) ||
            (clearhausPluginStatus.canViewApplicationData && t("plugins.yourInfoAtClearhaus")) ||
            undefined,
        editBtnAsText: clearhausPluginStatus.canViewApplicationData || false,
        buttonLink: clearhausPluginStatus.buttonLink || "/swiipepayments_onboard",
        actionButtonBlue: clearhausPluginStatus.buttonBlue,
    }

    const dankortCcStatus = getProviderStatus(webshop.providers?.gatewayProviders, "CreditCard", "DankortEpay")
    const dankort: IPlugin = {
        id: "Dankort",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        price: getPspPriceHtml("transaction", global.invoicingPrices),
        editNode:
            swiipePlanSelected && !!dankortCcStatus && dankortCcStatus !== "ActionRequired" && !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/dankort_details")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("cardPaymentsLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded:
            swiipePlanSelected && !!dankortCcStatus && dankortCcStatus !== "ActionRequired" && !merchant.isDeletionPending,
        logoWidth: 180,
        logoTopMargin: 15,
        title: "",
        buttonLink: "/dankort_onboard",
        buttonFunc: sandbox.getSandboxOnboardingAction("DankortEpay"),
        pluginDescription: t("plugins.dankortDescription"),
        legalTextType: ["TermsDankort"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.dankort")],
        onboardedText: swiipePlanSelected && dankortCcStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected || dankortCcStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        cardTypes: ["Dankort"],
        canEdit: false,
    }

    const forbrugsforeningenStatus = getProviderStatus(
        webshop.providers?.gatewayProviders,
        "CreditCard",
        "ForbrugsforeningenEpay"
    )
    const forbrugsforeningen: IPlugin = {
        id: "Forbrugsforeningen",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        price: getPspPriceHtml("transaction", global.invoicingPrices),
        editNode:
            swiipePlanSelected &&
            !!forbrugsforeningenStatus &&
            forbrugsforeningenStatus !== "ActionRequired" &&
            !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/forbrugsforeningen_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            ) : undefined,
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("cardPaymentsLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded:
            swiipePlanSelected &&
            !!forbrugsforeningenStatus &&
            forbrugsforeningenStatus !== "ActionRequired" &&
            !merchant.isDeletionPending,
        logoWidth: 180,
        logoTopMargin: 15,
        title: "",
        buttonLink: "/forbrugsforeningen_onboard",
        buttonFunc: sandbox.getSandboxOnboardingAction("ForbrugsforeningenEpay"),
        pluginDescription: t("plugins.forbrugsforeningenDescription"),
        legalTextType: ["TermsForbrugsforeningen"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.forbrugsforeningen")],
        onboardedText:
            swiipePlanSelected && forbrugsforeningenStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected || forbrugsforeningenStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        cardTypes: ["Forbrugsforeningen"],
        canEdit: false,
    }

    const mpoStatus = getProviderStatus(webshop.providers?.gatewayProviders, "MobilePayOnline", "MobilePayOnline")
    const clearhausMpoStatus = getProviderStatus(webshop.providers?.gatewayProviders, "MobilePayOnline", "Clearhaus")
    const mobilePayOnline: IPlugin = {
        id: "MobilePayOnline",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        price: getTextWithInvoicingPrices("plugins.mobilepayOnlinePrice", global.invoicingPrices, {
            mpoMonthly: { type: "MobilePayOnlineMonthlyShared" },
            mpoTx: { type: "MobilePayOnlineTransactionStep1Shared" },
        }),
        editNode:
            swiipePlanSelected && !!mpoStatus && mpoStatus !== "ActionRequired" && !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/mpo_details")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: MobilePayOnlineTextLogo,
        isFetched: webshop.providers ? true : false,
        isOnboarded:
            swiipePlanSelected &&
            !!mpoStatus &&
            mpoStatus != "ActionRequired" &&
            !!clearhausMpoStatus &&
            !merchant.isDeletionPending,
        logoWidth: 135,
        title: "",
        buttonLink: "/mobilepayonline_onboarding",
        buttonFunc: sandbox.getSandboxOnboardingAction("MobilePayOnline"),
        pluginDescription: t("plugins.mobilepayOnlineDescription"),
        legalTextType: ["TermsMobilePayOnline"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.mpo")],
        onboardedText:
            swiipePlanSelected && clearhausMpoStatus === "Ready" && mpoStatus === "Ready"
                ? t("plugins.onboarded")
                : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected || clearhausMpoStatus !== "Ready" || mpoStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        removeBtnMargin: true,
    }

    const swishStatus = getProviderStatus(webshop.providers?.gatewayProviders, "Swish", "Swish")
    const swish: IPlugin = {
        id: "Swish",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        editNode:
            swiipePlanSelected && !!swishStatus && swishStatus !== "ActionRequired" ? (
                <div>
                    <span onClick={() => navigationService.navigate("/swish_details")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        price: getTextWithInvoicingPrices("plugins.swishPrice", global.invoicingPrices, {
            swishTx: { type: "SwishTransactionShared" },
        }),
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("swishLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: swiipePlanSelected && !!swishStatus && swishStatus != "ActionRequired",
        logoWidth: 135,
        title: "",
        buttonLink: "/swish_onboarding",
        buttonFunc: sandbox.getSandboxOnboardingAction("Swish"),
        pluginDescription: t("plugins.swishDescription"),
        legalTextType: ["TermsSwish"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.swish")],
        onboardedText: swiipePlanSelected && swishStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected && swishStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        removeBtnMargin: true,
    }

    const vippsStatus = getProviderStatus(webshop.providers?.gatewayProviders, "Vipps", "Clearhaus")
    const vipps: IPlugin = {
        id: "Vipps",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        editNode:
            swiipePlanSelected && !!vippsStatus && vippsStatus !== "ActionRequired" ? (
                <div>
                    <span onClick={() => navigationService.navigate("/vipps_details")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        price: getTextWithInvoicingPrices("plugins.vippsPrice", global.invoicingPrices, {
            vippsTx: { type: "VippsTransactionShared" },
        }),
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("vippsLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: swiipePlanSelected && !!vippsStatus && vippsStatus != "ActionRequired",
        logoWidth: 135,
        title: "",
        buttonLink: "/vipps_onboarding",
        buttonFunc: sandbox.getSandboxOnboardingAction("Vipps"),
        pluginDescription: t("plugins.vippsDescription"),
        legalTextType: ["TermsVipps"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.vipps")],
        onboardedText: swiipePlanSelected && vippsStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected || vippsStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        removeBtnMargin: true,
    }

    const applePayStatus = getProviderStatus(webshop.providers?.gatewayProviders, "ApplePay", "Clearhaus")
    const applePay: IPlugin = {
        id: "ApplePay",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        editNode:
            swiipePlanSelected && !!applePayStatus && applePayStatus !== "ActionRequired" ? (
                <div>
                    <span onClick={() => navigationService.navigate("/applepay_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            ) : undefined,
        price: getTextWithInvoicingPrices("plugins.applePayPrice", global.invoicingPrices, {
            applePayTx: { type: "ApplePayTransactionShared" },
        }),
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("applePayLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: swiipePlanSelected && !!applePayStatus && applePayStatus != "ActionRequired",
        logoWidth: 135,
        title: "",
        buttonLink: "/applepay_onboarding",
        buttonFunc: sandbox.getSandboxOnboardingAction("ApplePay"),
        pluginDescription: t("plugins.applePayDescription"),
        legalTextType: ["TermsApplePay"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.applepay")],
        onboardedText: swiipePlanSelected && applePayStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected || applePayStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        removeBtnMargin: true,
    }

    const klarnaStatus = getProviderStatus(webshop.providers?.gatewayProviders, "KlarnaPayLater", "Klarna")
    const klarna: IPlugin = {
        id: "KlarnaPayLater",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        editNode:
            swiipePlanSelected && !!klarnaStatus && klarnaStatus !== "ActionRequired" ? (
                <div>
                    <span onClick={() => navigationService.navigate("/klarna_details")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        price: getTextWithInvoicingPrices("plugins.klarnaPrice", global.invoicingPrices, {
            klarnaTx: { type: "KlarnaTransactionShared" },
        }),
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("klarnaLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: swiipePlanSelected && !!klarnaStatus && klarnaStatus != "ActionRequired",
        logoWidth: 135,
        title: "",
        buttonLink: "/klarna_onboarding",
        buttonFunc: sandbox.getSandboxOnboardingAction("Klarna"),
        pluginDescription: t("plugins.klarnaDescription"),
        legalTextType: ["TermsKlarna"],
        legalTextLinkText: t("plugins.clickhere"),
        legalTextLinkOnboarded: [t("serviceNames.klarna")],
        onboardedText: swiipePlanSelected && klarnaStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: swiipePlanSelected && klarnaStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        removeBtnMargin: true,
    }

    const resursStatus = getProviderStatus(
        webshop.providers?.gatewayProviders,
        ["ResursInvoice", "ResursPartPayment", "ResursRevolvingCredit"],
        "Resurs"
    )
    const resurs: IPlugin = {
        id: "ResursInvoice",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        editNode:
            swiipePlanSelected && !!resursStatus && resursStatus !== "ActionRequired" ? (
                <div>
                    <span onClick={() => navigationService.navigate("/resurs_details")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        price: getTextWithInvoicingPrices("plugins.resursPrice", global.invoicingPrices, {
            resursInvoicePct: { type: "ResursInvoicePctTransactionShared" },
            resursPartPaymentPct: { type: "ResursPartPaymentPctTransactionShared" },
            resursRevolvingCreditPct: { type: "ResursRevolvingCreditPctTransactionShared" },
        }),
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("resursLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: swiipePlanSelected && !!resursStatus && resursStatus != "ActionRequired",
        logoWidth: 135,
        title: "",
        buttonLink: "/resurs_onboarding",
        buttonFunc: sandbox.getSandboxOnboardingAction("Resurs"),
        pluginDescription: t("plugins.resursDescription"),
        textUnderDescription: "plugins.resursTextUnderDescription",
        legalTextType: ["TermsResurs"],
        legalTextLinkText: t("plugins.clickhere"),
        legalTextLinkOnboarded: [t("serviceNames.resursPayments")],
        onboardedText: swiipePlanSelected && resursStatus === "Ready" ? t("plugins.onboarded") : t("plugins.waitingapproval"),
        onboardedTextItalic: !swiipePlanSelected && resursStatus !== "Ready",
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        removeBtnMargin: true,
    }

    const a2aProvider = getProvider("AccountToAccount", "AccountToAccount", webshop.providers?.gatewayProviders)
    // const a2aGateway = merchant.gateways?.find((gateway) => gateway.paymentType == "AccountToAccount")
    const accountToAccount: IPlugin = {
        id: "AccountToAccount",
        platforms: ["woocommerce", "magento", "magento2", "prestashop"] as TPlatformValue[],
        price: getTextWithInvoicingPrices("plugins.a2aPrice", global.invoicingPrices, {
            a2aMonthly: { type: "A2aMonthlyShared" },
            a2aMinTx: { type: "A2aMinTransactionShared" },
        }),
        logo: getImage("a2aLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: swiipePlanSelected && a2aProvider?.enabled,
        logoWidth: 190,
        logoTopMargin: 15,
        title: "",
        buttonLink: "/account_to_account_onboarding",
        editNode:
            swiipePlanSelected && !!a2aProvider && a2aProvider?.enabled && !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/account_to_account_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            ) : undefined,
        pluginDescription: t("plugins.a2aDescription"),
        legalTextType: ["TermsA2a"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.a2a")],
        onboardedText: t("plugins.onboarded"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
        showAiiaLogo: true,
    }

    const winBackEnabled = webshop.features && webshop.features["Winback"] ? webshop.features["Winback"].enabled : false
    const winBack: IPlugin = {
        id: "Winback",
        platforms: ["woocommerce", "magento2", "magento", "prestashop"] as TPlatformValue[],
        price: getTextWithInvoicingPrices("plugins.winbackPrice", global.invoicingPrices, {
            winbackTxPrice: { type: "SwiipeWinbackPctBasic" },
        }),
        logo: WinBackLogo,
        editNode:
            winBackEnabled && !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/winback_edit")}>{t("plugins.editNode.seeDetails")}</span>
                </div>
            ) : undefined,
        isFetched: !!webshop.features,
        isOnboarded: winBackEnabled && !merchant.isDeletionPending,
        logoWidth: 165,
        title: "",
        buttonLink: winBackEnabled ? "/winback_edit" : "/winback_onboard",
        pluginDescription: t("plugins.winbackDescription"),
        legalTextType: ["TermsWinBack"],
        legalTextLinkOnboarded: [t("serviceNames.winback")],
        legalTextLinkText: t("plugins.here"),
        onboardedText: t("plugins.active"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: true && !merchant.isDeletionPending,
    }

    const plusSellEnabled = webshop.features && webshop.features["PlusSell"] ? webshop.features["PlusSell"].enabled : false
    const plusSell: IPlugin = {
        id: "PlusSell",
        platforms: ["woocommerce", "magento2", "magento", "prestashop"] as TPlatformValue[],
        price: getTextWithInvoicingPrices("plugins.plusSellPrice", global.invoicingPrices, {
            plusSellTx: { category: "SwiipePlusSellPct" },
        }),
        logo: PlusSellLogo,
        editNode:
            plusSellEnabled && !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/plussell_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            ) : undefined,
        isFetched: !!webshop.features,
        isOnboarded: plusSellEnabled && !merchant.isDeletionPending,
        logoWidth: 160,
        title: "",
        buttonLink: plusSellEnabled ? "/plussell_details" : "/plussell_onboard",
        pluginDescription: t("plugins.plusSellDescription"),
        legalTextType: ["TermsPlusSell"],
        legalTextLinkOnboarded: [t("serviceNames.plusSell")],
        legalTextLinkText: t("plugins.here"),
        onboardedText: t("plugins.onboarded"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    const anywhereMobileEnabled =
        webshop.features && webshop.features["AnywhereMobile"] ? webshop.features["AnywhereMobile"].enabled : false
    const anywhereMobile: IPlugin = {
        id: "AnywhereMobile",
        platforms: ["woocommerce", "magento2"] as TPlatformValue[], // TODO: add magento to the list before new release of magento
        price: getTextWithInvoicingPrices("plugins.anywhereMobilePrice", global.invoicingPrices, {
            anywhereMobileMonthly: { category: "SwiipeAnywhereMobileMonthly" },
            anywhereMobileExtraDomainMonthly: {
                category: "SwiipeAnywhereMobileExtraWebshopMonthly",
            },
        }),
        logo: AnywhereMobileLogo,
        editNode:
            (anywhereMobileEnabled && !merchant.isDeletionPending && (
                <div>
                    <span onClick={() => navigationService.navigate("/anywhere_mobile_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            )) ||
            undefined,
        isFetched: !!webshop.features,
        isOnboarded: anywhereMobileEnabled && !merchant.isDeletionPending,
        logoWidth: 220,
        title: "",
        buttonLink: anywhereMobileEnabled ? "/anywhere_mobile_details" : "/anywhere_mobile_onboard",
        pluginDescription: t("plugins.anywhereMobileDescription"),
        legalTextType: ["TermsAnywhereMobile"],
        legalTextLinkOnboarded: [t("serviceNames.anywhereMobile")],
        legalTextLinkText: t("plugins.here"),
        onboardedText: t("plugins.onboarded"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    const returnSellEnabled = webshop.features && webshop.features["ReturnSell"] ? webshop.features["ReturnSell"].enabled : false
    const returnSell: IPlugin = {
        id: "ReturnSell",
        platforms: ["woocommerce", "magento2", "magento"] as TPlatformValue[],
        price: getTextWithInvoicingPrices("plugins.returnSellPrice", global.invoicingPrices, {
            returnSellMonthly: { category: "SwiipeReturnSellMonthly" },
            returnSellTx: { category: "SwiipeReturnSellTransactionPct" },
            returnSellExtraMonthly: { category: "SwiipeReturnSellExtraWebshopMonthly" },
        }),
        logo: ReturnSellLogo,
        editNode:
            returnSellEnabled && !merchant.isDeletionPending ? (
                <div>
                    <span onClick={() => navigationService.navigate("/returnsell_details")}>
                        {t("plugins.editNode.seeDetails")}
                    </span>
                </div>
            ) : undefined,
        isFetched: !!webshop.features,
        isOnboarded: returnSellEnabled && !merchant.isDeletionPending,
        logoWidth: 220,
        title: "",
        buttonLink: returnSellEnabled ? "/returnsell_details" : "/returnsell_onboard",
        pluginDescription: t("plugins.returnSellDescription"),
        legalTextType: ["TermsReturnSell"],
        legalTextLinkOnboarded: [t("serviceNames.returnSell")],
        legalTextLinkText: t("plugins.here"),
        onboardedText: t("plugins.onboarded"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    if (!webshop.details || !merchant.country) {
        return []
    }

    const viabillOnboardingStatus = getProviderStatus(webshop.providers?.gatewayProviders, "Viabill", "Viabill")
    const viabillEnabled =
        !merchant.isDeletionPending &&
        swiipePlanSelected &&
        !!viabillOnboardingStatus &&
        viabillOnboardingStatus !== "ActionRequired"

    const viabill: IPlugin = {
        id: "Viabill",
        platforms: ["woocommerce", "magento2", "magento", "prestashop"] as TPlatformValue[],
        price: getPspPriceHtml("transaction", global.invoicingPrices),
        editNode: viabillEnabled ? (
            <div>
                <span onClick={() => navigationService.navigate("/viabill_edit")}>{t("plugins.editNode.seeDetails")}</span>
            </div>
        ) : undefined,
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("viabillLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: viabillEnabled,
        logoWidth: 135,
        title: "",
        buttonLink: "/viabill_onboard",
        pluginDescription: t("plugins.viabillDescription"),
        legalTextType: ["TermsViaBill"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.viabill")],
        onboardedText: t("plugins.onboarded"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    const anydayOnboardingStatus = getProviderStatus(webshop.providers?.gatewayProviders, "Anyday", "Anyday")
    const anydayEnabled =
        !merchant.isDeletionPending &&
        swiipePlanSelected &&
        !!anydayOnboardingStatus &&
        anydayOnboardingStatus !== "ActionRequired"

    const anyday: IPlugin = {
        id: "Anyday",
        platforms: ["woocommerce", "magento2"] as TPlatformValue[],
        price: getPspPriceHtml("transaction", global.invoicingPrices),
        editNode: anydayEnabled ? (
            <div>
                <span onClick={() => navigationService.navigate("/anyday_edit")}>{t("plugins.editNode.seeDetails")}</span>
            </div>
        ) : undefined,
        priceSubText: !swiipePlanSelected || clearhausCcStatus !== "Ready" ? t("plugins.needsSwiipePayments") : undefined,
        logo: getImage("anydayLogo"),
        isFetched: webshop.providers ? true : false,
        isOnboarded: anydayEnabled,
        logoWidth: 135,
        title: "",
        buttonLink: "/anyday_onboard",
        pluginDescription: t("plugins.anydayDescription"),
        legalTextType: ["TermsAnyday"],
        legalTextLinkText: t("plugins.here"),
        legalTextLinkOnboarded: [t("serviceNames.anyday")],
        onboardedText: t("plugins.onboarded"),
        priceHasLotsOfText: true,
        priceAlignCenter: true,
        canEdit: false,
    }

    return filterPluginsByPlatform(
        [
            swiipePayments,
            viabill,
            anyday,
            dankort,
            forbrugsforeningen,
            mobilePayOnline,
            swish,
            // vipps,
            klarna,
            resurs,
            plusSell,
            // anywhereMobile,
            returnSell,
            global.env === "Sandbox" ? undefined : applePay, // Disabled until we can do apple pay on sandbox - currently we cannot ensure valid merchant url at apple
            global.env === "Sandbox" ? undefined : winBack,
            // accountToAccount,
        ].filter(hasValue),
        webshop.details.platform
    )
}

interface IMerchantPluginsPageProps {}
const MerchantPluginsPage = ({}: IMerchantPluginsPageProps) => {
    const dispatch = useReduxDispatch()
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const preOnboardingDetails = useMerchantPreOnboardingDetails()

    useEffect(() => {
        dispatch(getMerchantPreOnboardingDetailsThunk())
    }, [currentUserRelation?.id])

    if (!merchantDetails || !merchantDetails.webshops.length || !preOnboardingDetails) {
        return null
    }

    return (
        <MerchantPluginsPageInner
            merchantDetails={merchantDetails}
            preOnboardingDetails={preOnboardingDetails?.exists ? preOnboardingDetails : undefined}
        ></MerchantPluginsPageInner>
    )
}

const highlightedServices: TSwiipeService[] = ["PlusSell", "Winback", "CreditCard", "MobilePayOnline", "ReturnSell"]

interface IMerchantPluginsPageInnerProps {
    merchantDetails: MixedMerchantDetails
    preOnboardingDetails: IMerchantPreOnboardingDetails | undefined
}
const MerchantPluginsPageInner = ({ merchantDetails, preOnboardingDetails }: IMerchantPluginsPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const env = useSelector(sharedConfigurationSelectors.environment)
    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)

    const calculatedDeps = useCalculatedPluginsDependencies(merchantDetails, selectedWebshopId)

    const pluginData = getPluginData(calculatedDeps.webshop.details, calculatedDeps.merchant.country, env)
    const serviceData = useServiceData(
        calculatedDeps.webshop,
        calculatedDeps.merchant,
        calculatedDeps.global,
        calculatedDeps.clearhaus,
        calculatedDeps.sandbox
    )

    const termsStatuses = useSelector(legalSelectors.termsStatuses)
    useEffect(() => {
        dispatch(ensureTermsStatusThunk(false))
    }, [merchantDetails])

    const preOnboardedServices = mapMerchantOnboardingServicesSubFormToServices(preOnboardingDetails?.services)
    const yourServices = serviceData.filter((s) => (s.id && preOnboardedServices.includes(s.id)) || s.isOnboarded)
    const recommendServices = serviceData.filter(
        (s) => s.id && !yourServices.find((yourService) => yourService.id === s.id) && highlightedServices.includes(s.id)
    )
    const otherServices = serviceData.filter(
        (s) =>
            s.id &&
            !yourServices.find((yourService) => yourService.id === s.id) &&
            !recommendServices.find((yourService) => yourService.id === s.id)
    )

    const renderPlugin = (plugin: IPlugin, disableSeparator: boolean) => {
        const terms = !plugin.id ? [] : getTermsForSwiipeService(plugin.id)
        const foundTermsStatuses = terms.map((t) => termsStatuses?.find((ts) => ts.termsType === t))
        const isMissingTerms =
            !!termsStatuses && foundTermsStatuses.length > 0 && foundTermsStatuses.filter((ts) => !hasValue(ts)).length > 0
        const foundNotAcceptedTermsStatuses = foundTermsStatuses.filter(hasValue).find((ts) => !ts.isAnyAccepted)

        return (
            <Plugins
                key={plugin.id ?? ""}
                merchantDetails={merchantDetails}
                gateways={calculatedDeps.merchant.gateways}
                {...plugin}
                isTermsAccepted={
                    !termsStatuses || isMissingTerms || terms.length === 0 ? undefined : !foundNotAcceptedTermsStatuses
                }
                disableSeparator={disableSeparator}
            />
        )
    }

    return (
        <>
            <PageWrapper disableMinHeight>
                <PageContainer>
                    <WebshopSelect
                        merchantDetails={merchantDetails}
                        onWebshopChange={(selectedWebshopId: string) => setSelectedWebshopId(selectedWebshopId)}
                    />
                </PageContainer>
            </PageWrapper>
            <PageWrapper>
                <PageContainer id="plugins-top" title={t("plugins.plugins")}>
                    <div className="services-title">{t("plugins.yourServices")}</div>
                    {pluginData.map((pData) => renderPlugin(pData, false))}
                    {yourServices.map((pData) => renderPlugin(pData, false))}
                </PageContainer>

                {recommendServices.length > 0 && (
                    <PageContainer id="plugins-middle">
                        <div className="services-title">{t("plugins.recommendedServices")}</div>
                        {recommendServices.map((pData, index) => renderPlugin(pData, index == 0))}
                    </PageContainer>
                )}

                {otherServices.length > 0 && (
                    <AccordionWithChildren header={t("plugins.otherServices").toUpperCase()}>
                        <PageContainer id="plugins-bottom" noHeader>
                            {otherServices.map((pData, index) => renderPlugin(pData, index == 0))}
                        </PageContainer>
                    </AccordionWithChildren>
                )}
            </PageWrapper>
        </>
    )
}

export default MerchantPluginsPage
